<template>
  <div>
    <div class="title">{{ $t("titleI18n.AROrdersManagement") }}</div>
    <div class="flex-between">
      <el-form class="flex-row" ref="form" :model="form">
        <el-form-item class="flex-row project-view" :label="$t('formTitleI18n.ChooseSupplier')">
          <el-select
            v-model="form.supplier"
            :placeholder="$t('unitPage.selectPlaceholder')"
            @change="chooseUser"
          >
            <!-- <template slot="empty">{{ selectEmpty }}</template> -->
            <el-option
              v-for="(item, index) in supplierList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-link
        :underline="false"
        slot="reference"
        icon="el-icon-info"
        type="primary"
        @click="dialogVisInfo = true"
      >{{ $t("btnI18n.PaymentInstructions") }}</el-link>
    </div>
    <el-table :data="tableData" style="width: 1200px">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="tableEmpty"></div>
      </template>
      <el-table-column
        prop="id"
        :show-overflow-tooltip="true"
        :label="$t('tableI18n.ProjectNumber')"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user.username"
        :show-overflow-tooltip="true"
        :label="$t('tableI18n.SupplierName')"
        align="center"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="receivablesOrder.id"
        :label="$t('tableI18n.ContractReferenceNumber')"
        align="center"
      ></el-table-column>
      <el-table-column prop="receivablesOrder.amount" width="180" align="center">
        <template slot="header">
          {{ $t('tableI18n.AROrderAmount') }}
          <br />
          {{ $t('unitI18n.MillionUsDollars') }}
        </template>
        <template
          v-slot="scope"
        >{{ ((Number(scope.row.receivablesOrder.amount) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }}</template>
      </el-table-column>
      <el-table-column
        prop="createdAt"
        :label="$t('tableI18n.FinancingStartingDate')"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="promisedPaymentDate"
        :label="$t('tableI18n.PaymentDueDate')"
        width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="promisedPaymentDate"
        :label="$t('tableI18n.RemainingDays')"
        width="140"
        align="center"
      >
        <template v-slot="scope">
          <span
            :class="{'overdue' : Number(formatDays(scope.row.promisedPaymentDate)) <= 0}"
          >{{ formatDays(scope.row.promisedPaymentDate) }} {{ $t("unitI18n.Days") }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" :label="$t('tableI18n.Status')" width="110" align="center">
        <template
          v-slot="scope"
        >{{ scope.row.status === 3 ? $t("statusI18n.Payables") : $t("statusI18n.Paid") }}</template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="$t('dialogTitleI18n.PaymentInstructions')"
      :visible.sync="dialogVisInfo"
      width="40%"
    >
      <div class="dialog-pi">
        <div class="dialog-row flex-row-center">
          <el-image class="pi-img" :src="imgSrc[0]"></el-image>
        </div>
        <div class="dialog-row pi-ct">
          <div
            class="text-left-blank"
          >{{ $t('dialogCtI18n.PaymentInstructions1') }} {{ $t('dialogCtI18n.PaymentInstructions2') }}</div>
        </div>
        <div class="dialog-row pi-ct">
          <div>
            <span class="bank-info">{{ $t('formTitleI18n.BankAccountNumber') }}</span>
            <span class="color-text-main">1000000000000001</span>
          </div>
          <div>
            <span class="bank-info">{{ $t('formTitleI18n.AccountHolderName') }}</span>
            <span class="color-text-main">Advokate</span>
          </div>
          <div>
            <span class="bank-info">{{ $t('formTitleI18n.ReceivingBank') }}</span>
            <span class="color-text-main">HSBC</span>
          </div>
        </div>
        <div class="dialog-row pi-ct">
          <div class="text-left-blank" style="font-weight: 500; margin-bottom: 15px;">
            <span class="color-text-main">{{ $t('dialogCtI18n.PaymentInstructions3') }}</span>
          </div>
          <div class="text-left-blank">{{ $t('dialogCtI18n.PaymentInstructions4') }}</div>
          <div>{{ $t('dialogCtI18n.PaymentInstructions5') }}</div>
        </div>
      </div>
    </el-dialog>
    <AdPagination
      :currentPage="tablePageCP"
      :pageSize="tablePagePS"
      :pageTotal="tableTotal"
      @handlePage="handlePage"
    ></AdPagination>
  </div>
</template>
<script>
// import { apiGetFinanceRequests, apiGetMyBalance } from "@/utils/api";
import AdPagination from "@/components/ad-pagination";

export default {
  name: "order-manage",
  components: {
    AdPagination
  },
  data() {
    return {
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
      imgSrc: [require('@/static/img/dialog-pi.png')],
      form: {
        supplier: ""
      },
      supplierList: [],
      tableData: [],
      tableLoading: false,
      tableEmpty: this.$t('loading'),
      selectEmpty: this.$t('loading'),
      dialogVisInfo: false,
    };
  },
  mounted() {
    this.initData();
    this.getSpList();
  },
  methods: {
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.initData();
    },
    // 筛选数据
    initData() {
      this.tableLoading = true;
      const data = {
        spUserId: this.form.supplier,
        page: this.tablePageCP,
        pageSize: this.tablePagePS
      };
      if (!this.form.supplier) delete data.spUserId;
      let _this = this;
      this.$axios.get('/v1/brand-owner/repay-orders', { params: data }).then(result => {
        _this.tableLoading = false;
        if (!result.code) {
          _this.tableData = result.data.rows;
          _this.tableTotal = result.data.count || 0;
        }
      }).catch(error => {
        this.tableLoading = false;
      });
      this.tableEmpty = this.$t('tableEmpty');
    },
    getSpList() {
      let _this = this;
      this.$axios.get('/v1/brand-owner/supplier-rel').then(result => {
        if (!result.code) {
          _this.supplierList = result.data.rows;
        }
      }).catch(error => {
        // this.tableLoading = false;
      });
    },
    formatDays(dateEnd) {
      let startDate = Date.parse(this.formatDate(new Date()));
      let endDate = Date.parse(this.formatDate(dateEnd));
      // if (startDate > endDate){
      //   return 0;
      // }
      if (startDate === endDate) {
        return 1;
      }
      return Math.floor((endDate - startDate) / (1 * 24 * 60 * 60 * 1000));
    },
    formatDate(dateValue) {
      let date = new Date(dateValue);
      const sperator = "-";
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return year + sperator + month + sperator + day;
    },
    chooseUser() {
      this.tableLoading = true;
      this.initData();
    },
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style scoped lang="scss">
.overdue {
  color: #f56c6c;
}
.dialog-pi {
  width: 530px;
  min-width: 530px;
  margin: 0 auto;
  .dialog-row {
    width: 100%;
  }
  .pi-ct {
    margin: 15px 0;
    .bank-info {
      margin-left: 0;
    }
    .color-text-main {
      color: #81d8d0;
    }
  }
  .pi-img {
    width: 60px;
    height: 60px;
  }
  .text-left-blank {
    margin-left: 0;
  }
}
</style>
